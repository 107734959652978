/* Project detail page template */

import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Img,
  SimpleGrid,
  Text,
} from "@chakra-ui/react"
import { graphql, Link as GatsbyLink } from "gatsby"
import React from "react"
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage,
} from "react-compare-slider"
import { FaCamera } from "react-icons/fa"
import Seo from "../components/seo"

const ProjectDetailTemplate = ({ data }) => {
  const project = data.markdownRemark

  return (
    <>
      <Seo title={project.frontmatter.title} />
      <Flex
        direction="column"
        justify="center"
        align="center"
        py={[4, 8]}
        bg="gray.700"
      >
        <Heading as="h1" size="xl" color="teal.300" textAlign="center">
          {project.frontmatter.title}
        </Heading>
      </Flex>
      <Container maxW="container.xl" py={[4, 8]}>
        <Center>
          <Box maxW="800px" w="100%" py={[4, 8]}>
            <ReactCompareSlider
              onlyHandleDraggable
              boundsPadding={40}
              handle={<ReactCompareSliderHandle />}
              itemOne={
                <ReactCompareSliderImage
                  alt="before"
                  src={project.frontmatter.before}
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  alt="after"
                  src={project.frontmatter.featured}
                />
              }
              position={50}
              style={{
                flexGrow: 1,
                width: "100%",
              }}
            />
          </Box>
        </Center>
        <Text py={[4, 8]}>
          <Text as="span" fontWeight="semibold">
            Description
          </Text>
          : {project.frontmatter.description}
          <br />
          <Text as="span" fontWeight="semibold">
            Type
          </Text>
          :{" "}
          {project.frontmatter.type.charAt(0).toUpperCase() +
            project.frontmatter.type.slice(1)}
          <br />
          <Text as="span" fontWeight="semibold">
            Completion Date
          </Text>
          : {project.frontmatter.complete}
        </Text>
        <SimpleGrid columns={[1, 1, 2]} spacing={4} py={[4, 8]}>
          {project.frontmatter.after.map((item, index) => (
            <Img key={index} src={item.src} alt={item.title} />
          ))}
        </SimpleGrid>
        <Center py={[4, 8]}>
          <Button
            as={GatsbyLink}
            to="/projects"
            colorScheme="yellow"
            color="teal.800"
            leftIcon={<FaCamera />}
            minW="200px"
          >
            View All Projects
          </Button>
        </Center>
      </Container>
    </>
  )
}

export const query = graphql`
  query ProjectDetail($slug: String) {
    markdownRemark(
      fields: { collection: { eq: "projects" }, slug: { eq: $slug } }
    ) {
      frontmatter {
        title
        description
        featured
        before
        after {
          title
          src
        }
        type
        complete(formatString: "MMM. YYYY")
      }
    }
  }
`

export default ProjectDetailTemplate
